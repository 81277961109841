import * as React from "react"
import { TestimonialStyles } from "./TestimonialStyles"

const Testimonials = () => {
  const [checked, setChecked] = React.useState("1")

  const handleChange = (event) => {
    setChecked(event.target.value)
    console.log(event)
  }

  return (
    <TestimonialStyles>
      <div className="slider">
        <input
          type="radio"
          name="slider"
          title="slide1"
          checked={checked === "1"}
          value="1"
          onChange={handleChange}
          className="slider__nav"
        />
        <input
          type="radio"
          name="slider"
          title="slide2"
          checked={checked === "2"}
          value="2"
          className="slider__nav"
          onChange={handleChange}
        />
        <input
          type="radio"
          name="slider"
          title="slide3"
          value="3"
          className="slider__nav"
          checked={checked === "3"}
          onChange={handleChange}
        />
        <input
          type="radio"
          name="slider"
          title="slide4"
          value="4"
          checked={checked === "4"}
          className="slider__nav"
          onChange={handleChange}
        />
        <div className="slider__inner">
          <div className="slider__contents">
            <div className="image">
              <img
                src="https://imgur.com/5Zh8Hjc.jpg"
                alt="pics of James Gardiner"
              />
            </div>
            <h2 className="slider__caption">
              James Gardiner <br /> Artist (The Days Run)
            </h2>
            <p className="slider__txt">
              <i className="slider__image fa-solid fa-quote-left" />
              Kelvin has been absolutely first class, an excellent drummer and
              overall great asset for the band. His constant creativity has
              given us some great ideas for parts and he always maintains
              working with us in a totally professional way. He has also worked
              hard on our productions and listened to all our thoughts about the
              mixes he has produced. As I said, first class musicianship.
              <i className="slider__image fa-solid fa-quote-right" />
            </p>
          </div>
          <div className="slider__contents">
            <div className="image">
              <img
                src="https://imgur.com/IBZ7FDh.jpg"
                alt="pic of Dimitri Scarlato"
              />
            </div>
            <h2 className="slider__caption">
              Dr Dimitri Scarlato <br /> Composer, Conductor, Pianist
            </h2>
            <p className="slider__txt">
              <i className="slider__image fa-solid fa-quote-left" />
              Kelvin is a dedicated and passionate musician, a versatile drummer
              and I'd recommend having him in the studio anyday! He has a
              sincere drive for music and is always willing to go an extra mile
              to deliver great results. He is also a very lovely guy, very easy
              to work with, so go and check him out!
              <i className="slider__image fa-solid fa-quote-right" />
            </p>
          </div>
          <div className="slider__contents">
            <div className="image">
              <img src="https://imgur.com/mTvTR9Z.jpg" alt="pic of Alessio" />
            </div>
            <h2 className="slider__caption">
              Alessio Messinese <br /> Guitarist
            </h2>
            <p className="slider__txt">
              <i className="slider__image fa-solid fa-quote-left" />
              Don't be fooled by his age, this guy has exactly the same passion
              of the old school smokey musicians. It’s been a great experience
              working together with Kelvin. I appreciate his ability to adapt in
              so many different styles, his styles of playing and composition
              skills can smoothly match a wide range of guitarists. His music
              taste is also quite mature, an open minded musician.
              <i className="slider__image fa-solid fa-quote-right" />
            </p>
          </div>
          <div className="slider__contents">
            <div className="image">
              <img
                src="https://imgur.com/7o5F7PN.jpg"
                alt="pic of Andy Mecalfe"
              />
            </div>
            <h2 className="slider__caption">
              Andy Metcalfe <br /> (Bassist & Guitarist) soft boys, Squeeze
            </h2>
            <p className="slider__txt">
              <i className="slider__image fa-solid fa-quote-left" />
              It’s been my pleasure to work with Kelvin a number of times. His
              approach to the practicalities - punctuality, equipment in order,
              doing the homework on the repertoire - has always been very
              professional, but most importantly his drumming has been very
              musical and responsive to the performance, and he has always been
              a positive presence to have onboard.
              <i className="slider__image fa-solid fa-quote-right" />
            </p>
          </div>
        </div>
      </div>
    </TestimonialStyles>
  )
}

export default Testimonials
