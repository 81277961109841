import styled from "styled-components"

export const EventStyles = styled.div`
  display: flex;
  justify-content: start;
  height: auto;
  width: 100vw;
  padding: 75px 75px 75px 250px;
  @media (max-width: 490px) {
    padding: 20px;
  }

  $primary-color: blue;
  $black: #000;
  $dark-grey: #151515;
  $white: #f5f5f5;

  $heading-font: "Lato", sans-serif;
  $body-font: sans-serif;

  .event-list {
    list-style: none;
    margin: 0 0 1em;
    padding: 0;
  }

  .event {
    background-color: $dark-grey;
    margin-bottom: 1em;
    min-height: 120px;
    display: flex;
    flex-flow: row;

    .date {
      background-color: #0266c3;
      line-height: 1;
      padding: 0 12px 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      // min-width: 70px;

      @media (max-width: 490px) {
        width: 75px;
      }

      time {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      @media (min-width: 40em) {
        float: left;
        /* height: 120px; */
        margin-right: -120px;
        text-align: center;
        text-transform: uppercase;
        width: 120px;
      }

      a {
        color: inherit;
      }

      span {
        font-size: 2em;
        font-weight: 300;
        margin-right: 5px;
        @media (min-width: 40em) {
          display: block;
        }
      }

      .day {
        font-size: 1.5em;

        @media (min-width: 40em) {
          font-weight: 100;
        }
      }

      .month {
        font-weight: 900;
        @media (min-width: 40em) {
          font-size: 2.2em;
        }
      }

      .year,
      .time {
        line-height: 1.3;
        font-size: 1.3em;
        @media (min-width: 40em) {
          font-size: 0.7em;
          font-weight: 400;
        }
      }
    }

    .info {
      color: $white;
      padding: 6px 12px;

      @media (min-width: 40em) {
        margin-left: 120px;
      }

      .title {
        color: $white;
        font-weight: 300;
        font-size: 1.3em;
        @media (min-width: 40em) {
          font-size: 1.75em;
        }
      }

      .desc {
        color: $white;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }
`
