import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/selfie_side.jpeg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="Built For Performance"
          content="Versatile drummer with over 10 years of experience. Kelvin has played and recorded with the likes of Keny Charles, Andy Metcalfe, Amro Salah and Derrick Mcintyre across genres such as Jazz, Fusion, Latin, Rock, Soul, Funk and more."
        >
          {/* <StaticImage
            src="../../../static/logos/gatsby-logo.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          /> */}
          <h1>Drummer</h1>
        </Perk>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk
          title="Built For Sound"
          content="Professionally trained in using various digital audio workstations, capable of mixing, engineering and recording and has worked in the studio with the likes of FRNDS AS CMPNY, The Days Run and Beatkeepers. Can provide professional Drum & music theory tuition having been classically trained with years of experience teaching privately and in academic institutes."
        >
          {/* <StaticImage
            src="../../../static/logos/contentful-logo.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          /> */}{" "}
          <h1>Producer</h1>
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
