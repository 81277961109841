import * as React from "react"
import { useState } from "react"
import { CardStyles } from "./GalleryStyles"

const Gallery = () => {
  const [checked, setChecked] = useState("img1")

  const handleChange = (event) => {
    setChecked(event.target.value)
  }

  return (
    <CardStyles>
      <h2 style={{ textAlign: "center" }}>Gallery</h2>
      <section id="carousel">
        <input
          type="radio"
          name="carousel"
          value="img1"
          onChange={handleChange}
          checked={checked === "img1"}
          id="s1"
        />
        <input
          type="radio"
          name="carousel"
          value="img2"
          id="s2"
          onChange={handleChange}
          checked={checked === "img2"}
        />
        <input
          type="radio"
          name="carousel"
          value="img3"
          id="s3"
          onChange={handleChange}
          checked={checked === "img3"}
        />
        <input
          type="radio"
          name="carousel"
          value="img4"
          id="s4"
          onChange={handleChange}
          checked={checked === "img4"}
        />
        <input
          type="radio"
          name="carousel"
          value="img5"
          id="s5"
          onChange={handleChange}
          checked={checked === "img5"}
        />

        <label for="s1" id="slide1">
          <img src="https://imgur.com/EclK7he.jpg" alt="Kel" />
        </label>
        <label for="s2" id="slide2">
          <img src="https://imgur.com/w2A64Il.jpg" alt="Performing" />
        </label>
        <label for="s3" id="slide3">
          <img src="https://imgur.com/1ti2hVn.jpg" alt="Kel" />
        </label>
        <label for="s4" id="slide4">
          <img src="https://imgur.com/Bt8Cwej.jpg" alt="Kel" />
        </label>
        <label for="s5" id="slide5">
          <img src="https://imgur.com/YmzbYES.jpg" alt="Kel" />
        </label>
      </section>
    </CardStyles>
  )
}

export default Gallery
