import * as React from "react"
import { EventStyles } from "./EventStyles"

const EventList = () => {
  return (
    <EventStyles>
      <div className="row">
        <h2>Upcoming Events</h2>
        <ul className="event-list">
          <li className="event">
            <div className="date">
              <time datetime="2015-06-06">
                <span className="day">24</span>
                <span className="month">Jun</span>
                <span className="year">2020</span>
              </time>
            </div>
            <div className="info">
              <h2 className="title">Glastonbury Festival, UK</h2>
              <p className="desc">The Days Run - TBC</p>
            </div>
          </li>

          <li className="event">
            <div className="date">
              <time datetime="2015-07-26">
                <span className="day">20</span>
                <span className="month">Jun</span>
                <span className="year">2020</span>
              </time>
            </div>
            <div className="info">
              <h2 className="title">Canvey Island Club, Canvey Island, UK</h2>
              <p className="desc">The Beatkeepers - 3 Piece covers band</p>
            </div>
          </li>

          <li className="event">
            <div className="date">
              <time datetime="2015-08-15">
                <span className="day">10</span>
                <span className="month">Feb</span>
                <span className="year">2019</span>
              </time>
            </div>
            <div className="info">
              <h2 className="title">The Old Eagle, Camden, London</h2>
              <p className="desc">Jazzetudè - 4 Piece jazz band.</p>
            </div>
          </li>

          <li className="event">
            <div className="date">
              <time datetime="2015-08-15">
                <span className="day">27</span>
                <span className="month">Jan</span>
                <span className="year">2019</span>
              </time>
            </div>
            <div className="info">
              <h2 className="title">The Harrison, Kings Cross, London</h2>
              <p className="desc">Can’t count quartet - 5 piece Jazz band</p>
            </div>
          </li>

          <li className="event">
            <div className="date">
              <time datetime="2015-08-15">
                <span className="day">14</span>
                <span className="month">Dec</span>
                <span className="year">2019</span>
              </time>
            </div>
            <div className="info">
              <h2 className="title">
                Navel & Military Club, Southend-On-Sea, UK
              </h2>
              <p className="desc">The Beatkeepers - 3 Piece covers band.</p>
            </div>
          </li>
        </ul>
      </div>
    </EventStyles>
  )
}

export default EventList
